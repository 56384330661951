import React, {memo, useCallback, useContext, useReducer, useState} from 'react';
import {useTranslation} from 'gatsby-plugin-react-i18next';
import {UserContext} from '../../context/user-context';
import {cancelDeleteAccount, deleteAccount, getUser} from '../../requests';
import Modal from '../modal';
import './styles.scss';

const DeleteAccount = () => {
  const {t, i18n} = useTranslation();
  const {user, setUser} = useContext(UserContext);
  const [showModal, setShowModal] = useReducer(val => !val, false);
  const [showCancelModal, setShowCancelModal] = useReducer(val => !val, false);
  const [loading, setLoading] = useState(false);

  const getUserInfo = useCallback(async () => {
    const userInfo = await getUser();
    userInfo && setUser(userInfo);
  }, [getUser]);

  const handleCancel = useCallback(async () => {
    setLoading(true);
    const res = await cancelDeleteAccount();
    if (res) {
      setShowCancelModal();
      getUserInfo();
    }
    setLoading(false);
  }, [user, cancelDeleteAccount, getUserInfo]);

  const handleClick = useCallback(async () => {
    setLoading(true);
    const res = await deleteAccount();
    if (res) {
      setShowModal();
      getUserInfo();
    }
    setLoading(false);
  }, [user, getUserInfo, deleteAccount]);

  return (
    <>
      {user && (
        <div className={`delete-account ${i18n.language === 'ar' ? 'left-align' : ''}`}>
          {user.deleted_at ? (
            <>
              {t('account-is-scheduled')}{' '}
              {(Math.round(new Date(user.deleted_at) - new Date()) / 36e5).toFixed(0)} {t('hours')}.
              <span className="clickable-link d-block mx-2" onClick={handleCancel}>
                {loading ? (
                  <div className="spinner-border text-secondary" role="status">
                    <span className="sr-only"></span>
                  </div>
                ) : (
                  t('cancel-deletion')
                )}
              </span>
            </>
          ) : (
            <span className="danger-color" onClick={setShowModal}>
              {t('Delete-My-Account')}
            </span>
          )}
        </div>
      )}
      {showModal && (
        <Modal
          isOpen={showModal}
          modalToggle={setShowModal}
          title={t('confirm-deleting-account')}
          onButtonClick={handleClick}
          buttonText={
            loading ? (
              <div className="spinner-border text-light" role="status">
                <span className="sr-only"></span>
              </div>
            ) : (
              t('confirm')
            )
          }
          danger
        />
      )}
      {showCancelModal && (
        <Modal
          isOpen={showCancelModal}
          modalToggle={setShowCancelModal}
          title={t('happy-with-your-decision')}
          onButtonClick={setShowCancelModal}
          buttonText={t('close')}
        />
      )}
    </>
  );
};

export default memo(DeleteAccount);
