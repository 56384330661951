import {Link} from 'gatsby-plugin-react-i18next';
import React, {memo} from 'react';
import {useTranslation} from 'gatsby-plugin-react-i18next';
import DeleteAccount from '../deleteAccount';
import './styles.scss';

const Footer = () => {
  const {t, i18n} = useTranslation();
  return (
    <footer className="footer navbar">
      {global &&
        global.window &&
        global.window.location &&
        global.window.location.pathname &&
        (global.window.location.pathname === '/my-account/' ||
          global.window.location.pathname === '/my-account' ||
          global.window.location.pathname === '/ar/my-account' ||
          global.window.location.pathname === '/ar/my-account/') && <DeleteAccount />}

      <div className="row">
        <div className="d-none d-md-block col-xl-7 footer-right">
          &copy; {new Date().getFullYear()} Hexact, Inc. All rights reserved. 1250 E. Hallandale Beach Blvd., Unit 808,
          Hallandale Beach, Florida 33009
        </div>
        <div className="col-xl-5 footer-left">
          <Link to="/contact/" style={{whiteSpace: 'nowrap'}}>
            Contact us
          </Link>
          <Link to="/terms-of-use/" style={{whiteSpace: 'nowrap'}}>
            Terms of Service
          </Link>
          <Link to="/privacy-policy/" style={{whiteSpace: 'nowrap'}}>
            Privacy Policy
          </Link>
          <Link to="/cookie-policy/" style={{whiteSpace: 'nowrap'}}>
            Cookie Policy
          </Link>
        </div>
        <div className="d-block d-md-none text-center footer-right mt-2">
          <div className="py-1" dir="ltr">&copy; {new Date().getFullYear()} Hexact, Inc.</div>
          <div className="py-1" dir="ltr">All rights reserved.</div>
          <div className="py-1" dir="ltr">1250 E. Hallandale Beach Blvd., Unit 808</div>
          <div className="py-1" dir="ltr">Hallandale Beach, Florida 33009</div>
        </div>
      </div>
    </footer>
  );
};

export default memo(Footer);
