import React from 'react';

export const arrowRight = (
  <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.2661 15.8857L20.2582 10.8835C20.746 10.3945 20.746 9.60541 20.2582 9.11645L15.2661 4.11426L13.4953 5.88128L16.3578 8.75004L0.624649 8.75003L0.624649 11.2511L16.3578 11.2511L13.4953 14.1186L15.2661 15.8857Z"
      fill="white"
    />
    <clipPath id="clip0_101:421">
      <rect width="20" height="20" fill="white" transform="translate(20.625) rotate(90)" />
    </clipPath>
  </svg>
);
export const arrowDownIcon = (
  <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.8082 1.04845L11.4151 0.652642C11.2914 0.529031 11.1268 0.460933 10.9509 0.460933C10.7751 0.460933 10.6103 0.529031 10.4866 0.652642L6.00268 5.13678L1.51357 0.647666C1.39006 0.524055 1.22528 0.456055 1.04947 0.456055C0.873666 0.456055 0.708786 0.524055 0.585176 0.647666L0.192002 1.04104C-0.0640005 1.29684 -0.0640005 1.71353 0.192002 1.96934L5.53683 7.33338C5.66034 7.45689 5.82493 7.54392 6.00229 7.54392H6.00434C6.18025 7.54392 6.34483 7.45679 6.46835 7.33338L11.8082 1.98387C11.9319 1.86036 11.9998 1.6908 12 1.51499C12 1.33909 11.9319 1.17187 11.8082 1.04845Z"
      fill="#BBC2C5"
    />
  </svg>
);
export const step1 = (
  <svg width="78" height="78" viewBox="0 0 78 78" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="39" cy="39" r="39" fill="#7879F1" fillOpacity="0.2" />
    <circle cx="39" cy="38.9998" r="30.9998" fill="#7879F1" fillOpacity="0.5" />
    <path
      d="M20.1584 49.079C19.313 49.079 18.6925 48.7918 18.2968 48.2174C17.9011 47.643 17.9011 46.9251 18.2968 46.0635L25.0148 31.6321C25.3386 30.9321 25.7343 30.4205 26.202 30.0974C26.6696 29.7743 27.2182 29.6128 27.8478 29.6128C28.4593 29.6128 28.9989 29.7743 29.4666 30.0974C29.9342 30.4205 30.3299 30.9321 30.6537 31.6321L37.3718 46.0635C37.7855 46.943 37.8034 47.67 37.4257 48.2444C37.048 48.8008 36.4544 49.079 35.645 49.079C34.9256 49.079 34.368 48.9175 33.9723 48.5944C33.5945 48.2713 33.2528 47.7507 32.947 47.0328L32.0836 44.9865H23.5849L22.7215 47.0328C22.3978 47.7507 22.065 48.2713 21.7233 48.5944C21.3815 48.9175 20.8599 49.079 20.1584 49.079ZM27.7938 35.0246L25.2037 41.1902H30.4379L27.8478 35.0246H27.7938Z"
      fill="white"
      fillOpacity="0.8"
    />
    <path
      d="M44.1356 49.1329C43.1103 49.1329 42.202 48.9444 41.4106 48.5674C40.6371 48.1905 40.0256 47.6789 39.5759 47.0328C39.1442 46.3686 38.9284 45.6148 38.9284 44.7711C38.9284 43.8198 39.1802 43.0659 39.6838 42.5095C40.1875 41.9351 40.9969 41.5312 42.112 41.2979C43.2272 41.0466 44.7111 40.921 46.5638 40.921H47.589V40.544C47.589 39.8978 47.4181 39.4401 47.0764 39.1709C46.7526 38.9016 46.186 38.767 45.3766 38.767C44.927 38.767 44.4323 38.8209 43.8927 38.9286C43.3711 39.0183 42.7776 39.1888 42.112 39.4401C41.5185 39.6555 41.0238 39.6466 40.6281 39.4132C40.2324 39.1799 39.9716 38.8478 39.8457 38.417C39.7378 37.9683 39.7828 37.5285 39.9806 37.0977C40.1965 36.649 40.6012 36.3169 41.1947 36.1015C42.0581 35.7964 42.8495 35.59 43.569 35.4823C44.3064 35.3566 44.9809 35.2938 45.5925 35.2938C47.8049 35.2938 49.4417 35.7695 50.5029 36.7208C51.5821 37.6721 52.1217 39.1709 52.1217 41.2171V46.6558C52.1217 48.2713 51.3842 49.079 49.9093 49.079C48.5063 49.079 47.7599 48.361 47.67 46.9251C47.4181 47.6071 46.9865 48.1456 46.3749 48.5405C45.7634 48.9354 45.0169 49.1329 44.1356 49.1329ZM45.2148 46.0096C45.8803 46.0096 46.4379 45.7942 46.8875 45.3635C47.3552 44.9327 47.589 44.3673 47.589 43.6672V43.1826H46.5638C45.5025 43.1826 44.7201 43.2993 44.2165 43.5326C43.7309 43.748 43.488 44.098 43.488 44.5827C43.488 44.9955 43.6319 45.3365 43.9197 45.6058C44.2255 45.875 44.6572 46.0096 45.2148 46.0096Z"
      fill="white"
      fillOpacity="0.8"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M58.9623 27V28.3833L56.9641 28.3833V50.055H58.9623V51.4383H53.429L53.429 50.055H55.4271V28.3833L53.429 28.3833L53.429 27L58.9623 27Z"
      fill="white"
      fillOpacity="0.8"
    />
  </svg>
);

export const step2 = (
  <svg width="78" height="78" viewBox="0 0 78 78" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="39" cy="39" r="39" fill="#7879F1" fillOpacity="0.2" />
    <circle cx="39" cy="38.9998" r="30.9998" fill="#7879F1" fillOpacity="0.5" />
    <path
      d="M35.8966 49.4703L35.8506 49.3667H24.1003C23.7637 49.3577 23.4929 49.0869 23.4839 48.7503V30.6351H52.2837V39.0446L52.3181 39.0906C52.7733 39.6666 53.8041 41.1411 53.8041 41.1411L53.8044 26.1369C53.8028 25.5706 53.5771 25.0278 53.1767 24.6274C52.7763 24.2271 52.2335 24.0015 51.6673 24H24.1004C23.5332 24 22.9891 24.2247 22.5874 24.6254C22.1855 25.026 21.9593 25.5696 21.9577 26.1368V48.7501C21.9562 49.3199 22.1812 49.8671 22.5828 50.2711C22.9847 50.675 23.5306 50.9029 24.1005 50.9044H36.7033L36.5305 50.6336C36.2885 50.2634 36.0766 49.8743 35.8969 49.4701L35.8966 49.4703ZM33.9555 26.2402C34.2609 26.2402 34.5541 26.3616 34.7701 26.5776C34.9861 26.7939 35.1074 27.0867 35.1074 27.3922C35.1074 27.6977 34.9861 27.9908 34.7701 28.2068C34.5541 28.4228 34.2609 28.5442 33.9555 28.5442C33.65 28.5442 33.3571 28.4228 33.1408 28.2068C32.9248 27.9908 32.8035 27.6977 32.8035 27.3922C32.8035 27.0867 32.9248 26.7939 33.1408 26.5776C33.3571 26.3616 33.65 26.2402 33.9555 26.2402ZM30.2807 26.2402C30.5862 26.2402 30.8793 26.3616 31.0953 26.5776C31.3113 26.7939 31.4327 27.0867 31.4327 27.3922C31.4327 27.6977 31.3113 27.9908 31.0953 28.2068C30.8793 28.4228 30.5862 28.5442 30.2807 28.5442C29.9752 28.5442 29.682 28.4228 29.466 28.2068C29.2501 27.9908 29.1287 27.6977 29.1287 27.3922C29.1287 27.0867 29.2501 26.7939 29.466 26.5776C29.682 26.3616 29.9752 26.2402 30.2807 26.2402ZM26.6115 26.2402C26.917 26.2402 27.2102 26.3616 27.4262 26.5776C27.6422 26.7939 27.7635 27.0867 27.7635 27.3922C27.7635 27.6977 27.6422 27.9908 27.4262 28.2068C27.2102 28.4228 26.917 28.5442 26.6115 28.5442C26.3061 28.5442 26.0132 28.4228 25.7969 28.2068C25.5809 27.9908 25.4596 27.6977 25.4596 27.3922C25.4596 27.0867 25.5809 26.7939 25.7969 26.5776C26.0132 26.3616 26.3061 26.2402 26.6115 26.2402Z"
      fill="white"
      fillOpacity="0.8"
    />
    <path
      d="M37.8839 32.5483H26.6115C26.2554 32.5483 25.9664 32.8371 25.9664 33.1935C25.9664 33.5499 26.2554 33.8387 26.6115 33.8387H37.8839C38.2403 33.8387 38.5291 33.5499 38.5291 33.1935C38.5291 32.8371 38.2403 32.5483 37.8839 32.5483Z"
      fill="white"
      fillOpacity="0.8"
    />
    <path
      d="M38.5291 36.6437C38.5291 36.2873 38.2403 35.9985 37.8839 35.9985H26.6115C26.2554 35.9985 25.9663 36.2873 25.9663 36.6437C25.9663 36.9998 26.2554 37.2886 26.6115 37.2886H37.8839C38.239 37.2855 38.526 36.9986 38.5291 36.6437Z"
      fill="white"
      fillOpacity="0.8"
    />
    <path
      d="M35.004 40.0924C35.004 39.7363 34.7152 39.4473 34.3588 39.4473H26.6115C26.2554 39.4473 25.9663 39.7363 25.9663 40.0924C25.9663 40.4488 26.2554 40.7376 26.6115 40.7376H34.3588C34.7152 40.7376 35.004 40.4488 35.004 40.0924V40.0924Z"
      fill="white"
      fillOpacity="0.8"
    />
    <path
      d="M32.7921 43.5436C32.7921 43.1872 32.5034 42.8984 32.1472 42.8984H26.6118C26.2556 42.8984 25.9666 43.1872 25.9666 43.5436C25.9666 43.8997 26.2556 44.1888 26.6118 44.1888H32.1472C32.3182 44.1888 32.4823 44.1206 32.6034 43.9998C32.7243 43.8787 32.7921 43.7146 32.7921 43.5436V43.5436Z"
      fill="white"
      fillOpacity="0.8"
    />
    <path
      d="M26.6115 46.3486C26.2554 46.3486 25.9664 46.6374 25.9664 46.9935C25.9664 47.35 26.2554 47.6387 26.6115 47.6387H30.8682C31.2244 47.6387 31.5134 47.35 31.5134 46.9935C31.5134 46.6374 31.2244 46.3486 30.8682 46.3486H26.6115Z"
      fill="white"
      fillOpacity="0.8"
    />
    <path
      d="M58.2452 56.3424L51.7825 50.4613C51.8169 50.4153 51.8573 50.3692 51.8861 50.3229V50.3232C53.0271 48.6664 53.5339 46.6548 53.3143 44.6553C53.0947 42.656 52.1633 40.8023 50.6904 39.4325C49.2173 38.0629 47.3008 37.2689 45.2907 37.1951C43.2807 37.1213 41.3112 37.7729 39.7416 39.0311C38.1723 40.289 37.1077 42.0697 36.7424 44.0477C36.377 46.0256 36.7352 48.0688 37.7514 49.8048C37.7547 49.82 37.7547 49.8357 37.7514 49.8508C38.1265 50.4433 38.5691 50.9902 39.0702 51.4809C40.4622 52.8663 42.2953 53.7203 44.2514 53.8952C46.2075 54.0698 48.1628 53.5539 49.7781 52.437L49.9049 52.3333L55.7973 58.8017C56.1136 59.1558 56.5612 59.3641 57.0356 59.3777H57.122C57.5702 59.37 57.9976 59.1882 58.3144 58.8709C58.6564 58.5346 58.8433 58.0712 58.8302 57.5916C58.8171 57.112 58.6052 56.6595 58.2452 56.3424L58.2452 56.3424ZM49.0984 49.649C48.1043 50.641 46.7842 51.2386 45.3828 51.3312C43.9816 51.4235 42.5943 51.0044 41.4786 50.1514C40.3628 49.2988 39.5945 48.0699 39.316 46.6934C39.0375 45.3172 39.2679 43.8862 39.9642 42.6668C40.6608 41.4475 41.776 40.522 43.1032 40.0626C44.43 39.603 45.8787 39.6406 47.1804 40.168C47.9012 40.4547 48.5543 40.8882 49.0985 41.4411C50.1892 42.5283 50.8023 44.005 50.8023 45.5451C50.8023 47.0851 50.1893 48.5619 49.0985 49.6491L49.0984 49.649Z"
      fill="white"
      fillOpacity="0.8"
    />
  </svg>
);

export const step3 = (
  <svg width="78" height="78" viewBox="0 0 78 78" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="39" cy="39" r="39" fill="#7879F1" fillOpacity="0.2" />
    <circle cx="39" cy="38.9998" r="30.9998" fill="#7879F1" fillOpacity="0.5" />
    <path
      d="M58.2718 39L61 41.82L48.2749 55L41.5128 48L44.241 45.18L48.2749 49.34L58.2718 39V39ZM36.641 48L42.4872 54H23V50C23 45.58 29.9764 42 38.5897 42L42.2728 42.22L36.641 48ZM38.5897 22C40.6571 22 42.6397 22.8429 44.1016 24.3431C45.5634 25.8434 46.3846 27.8783 46.3846 30C46.3846 32.1217 45.5634 34.1566 44.1016 35.6569C42.6397 37.1571 40.6571 38 38.5897 38C36.5224 38 34.5398 37.1571 33.0779 35.6569C31.6161 34.1566 30.7949 32.1217 30.7949 30C30.7949 27.8783 31.6161 25.8434 33.0779 24.3431C34.5398 22.8429 36.5224 22 38.5897 22V22Z"
      fill="white"
      fillOpacity="0.8"
    />
  </svg>
);

export const copyIcon = (
  <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18.8248 2.57582L16.7982 0.54918C16.4466 0.197548 15.9697 2.6003e-06 15.4724 0L8.74902 0C7.71348 0 6.87402 0.839453 6.87402 1.875V3.75H3.74902C2.71348 3.75 1.87402 4.58945 1.87402 5.625V18.125C1.87402 19.1605 2.71348 20 3.74902 20H12.499C13.5346 20 14.374 19.1605 14.374 18.125V16.25H17.499C18.5346 16.25 19.374 15.4105 19.374 14.375V3.90164C19.374 3.40436 19.1765 2.92745 18.8248 2.57582ZM12.2646 18.125H3.9834C3.92124 18.125 3.86162 18.1003 3.81767 18.0564C3.77372 18.0124 3.74902 17.9528 3.74902 17.8906V5.85938C3.74902 5.79721 3.77372 5.7376 3.81767 5.69365C3.86162 5.64969 3.92124 5.625 3.9834 5.625H6.87402V14.375C6.87402 15.4105 7.71348 16.25 8.74902 16.25H12.499V17.8906C12.499 17.9528 12.4743 18.0124 12.4304 18.0564C12.3864 18.1003 12.3268 18.125 12.2646 18.125ZM17.2646 14.375H8.9834C8.92124 14.375 8.86162 14.3503 8.81767 14.3064C8.77372 14.2624 8.74902 14.2028 8.74902 14.1406V2.10938C8.74902 2.04721 8.77372 1.9876 8.81767 1.94365C8.86162 1.89969 8.92124 1.875 8.9834 1.875H13.124V5.3125C13.124 5.83027 13.5437 6.25 14.0615 6.25H17.499V14.1406C17.499 14.2028 17.4743 14.2624 17.4304 14.3064C17.3864 14.3503 17.3268 14.375 17.2646 14.375V14.375ZM17.499 4.375H14.999V1.875H15.3753C15.4374 1.875 15.497 1.89969 15.541 1.94363L17.4304 3.83301C17.4522 3.85477 17.4694 3.88061 17.4812 3.90905C17.493 3.93749 17.499 3.96797 17.499 3.99875V4.375Z"
      fill="#898393"
    />
  </svg>
);
export const eyeIcon = (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0)">
      <path
        d="M19.8666 9.62693C18.0488 7.41227 14.2003 4.82776 10 4.82776C5.78469 4.82776 1.93602 7.42984 0.133471 9.62693C-0.0444902 9.84384 -0.0444902 10.1562 0.133471 10.3731C1.99433 12.6414 5.85186 15.1723 10 15.1723C14.2956 15.1723 18.1277 12.4917 19.8666 10.3732C20.0445 10.1562 20.0445 9.84384 19.8666 9.62693ZM13.9958 10C13.9958 12.2033 12.2033 13.9959 9.99998 13.9959C7.79661 13.9959 6.00414 12.2032 6.00414 10C6.00414 7.79674 7.79665 6.00419 9.99998 6.00419C12.2035 6.00423 13.9958 7.79697 13.9958 10ZM1.38284 10.0002C2.04492 9.31207 3.64131 7.82701 5.8823 6.87388C4.47594 8.7218 4.47539 11.2767 5.88163 13.1253C4.17775 12.4032 2.59492 11.2622 1.38284 10.0002ZM14.1177 13.1262C15.5241 11.2782 15.5246 8.72333 14.1184 6.87474C15.8222 7.5969 17.4051 8.73788 18.6172 9.99984C17.9551 10.688 16.3587 12.173 14.1177 13.1262Z"
        fill="#BBC2C5"
      />
      <path
        d="M8 9.99999C8 11.1028 8.89717 12 9.99999 12C11.1028 12 12 11.1028 12 9.99999C12 8.89717 11.1028 8 9.99999 8C8.89717 8 8 8.89717 8 9.99999ZM10.8235 9.99999C10.8235 10.4541 10.4541 10.8235 9.99999 10.8235C9.54588 10.8235 9.17647 10.4541 9.17647 9.99999C9.17647 9.54588 9.54588 9.17647 9.99999 9.17647C10.4541 9.17647 10.8235 9.54588 10.8235 9.99999Z"
        fill="#BBC2C5"
      />
      <path
        d="M10.0003 2.54902C10.3252 2.54902 10.5886 2.28565 10.5886 1.96078V0.588235C10.5886 0.263373 10.3252 0 10.0003 0C9.67548 0 9.41211 0.263373 9.41211 0.588235V1.96078C9.41211 2.28565 9.67548 2.54902 10.0003 2.54902Z"
        fill="#BBC2C5"
      />
      <path
        d="M13.5098 3.33577C13.7918 3.49663 14.1512 3.39856 14.3122 3.1162L15.095 1.74365C15.2559 1.46146 15.1577 1.10224 14.8755 0.941263C14.5932 0.780321 14.2341 0.878557 14.0731 1.16083L13.2903 2.53338C13.1293 2.81554 13.2276 3.17479 13.5098 3.33577Z"
        fill="#BBC2C5"
      />
      <path
        d="M5.68831 3.1162C5.84926 3.39839 6.20847 3.49667 6.49071 3.33573C6.7729 3.17478 6.87118 2.81553 6.71024 2.53333L5.92741 1.16078C5.76647 0.878589 5.40718 0.780314 5.12502 0.941256C4.84282 1.1022 4.74455 1.46145 4.90549 1.74365L5.68831 3.1162Z"
        fill="#BBC2C5"
      />
      <path
        d="M10.0003 17.4509C9.67548 17.4509 9.41211 17.7143 9.41211 18.0392V19.4117C9.41211 19.7366 9.67548 19.9999 10.0003 19.9999C10.3252 19.9999 10.5886 19.7366 10.5886 19.4117V18.0392C10.5886 17.7143 10.3252 17.4509 10.0003 17.4509Z"
        fill="#BBC2C5"
      />
      <path
        d="M6.4907 16.6643C6.20851 16.5034 5.84929 16.6016 5.68831 16.8838L4.90549 18.2563C4.74455 18.5385 4.84282 18.8978 5.12502 19.0587C5.40725 19.2197 5.76647 19.1214 5.92741 18.8392L6.71023 17.4667C6.87121 17.1845 6.7729 16.8252 6.4907 16.6643Z"
        fill="#BBC2C5"
      />
      <path
        d="M14.3122 16.8838C14.1512 16.6016 13.7919 16.5034 13.5098 16.6642C13.2276 16.8252 13.1293 17.1844 13.2903 17.4666L14.0731 18.8392C14.234 19.1214 14.5932 19.2197 14.8755 19.0588C15.1577 18.8978 15.2559 18.5386 15.095 18.2564L14.3122 16.8838Z"
        fill="#BBC2C5"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export const checkedIcon = (
  <svg width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.7071 2.07017L4.43168 7.34522C4.04103 7.73595 3.40733 7.73595 3.01631 7.34522L0.293099 4.62181C-0.0976998 4.23109 -0.0976998 3.59732 0.293099 3.20652C0.683973 2.81564 1.31762 2.81564 1.70832 3.20637L3.7242 5.22227L8.29165 0.654799C8.68253 0.263926 9.31623 0.264222 9.70695 0.654799C10.0977 1.0456 10.0977 1.67915 9.7071 2.07017Z"
      fill="#825fec"
    />
  </svg>
);

export const playIcon = (
  <svg width="25" height="22" viewBox="0 0 25 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M22.8878 9.17238C24.4715 9.87621 24.4715 12.1238 22.8878 12.8276L2.81228 21.7501C1.4897 22.3379 9.29534e-07 21.3698 9.92798e-07 19.9225L1.77283e-06 2.07752C1.83609e-06 0.630206 1.4897 -0.337911 2.81228 0.2499L22.8878 9.17238Z"
      fill="#E0E0FF"
    />
  </svg>
);

export const poweredByStripe = (
  <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 150 34">
    <title>Powered by Stripe - blurple</title>
    <path
      class="cls-1"
      d="M146,0H3.73A3.73,3.73,0,0,0,0,3.73V30.27A3.73,3.73,0,0,0,3.73,34H146a4,4,0,0,0,4-4V4A4,4,0,0,0,146,0Zm3,30a3,3,0,0,1-3,3H3.73A2.74,2.74,0,0,1,1,30.27V3.73A2.74,2.74,0,0,1,3.73,1H146a3,3,0,0,1,3,3Z"
    />
    <path
      class="cls-1"
      d="M17.07,11.24h-4.3V22h1.92V17.84h2.38c2.4,0,3.9-1.16,3.9-3.3S19.47,11.24,17.07,11.24Zm-.1,5H14.69v-3.3H17c1.38,0,2.11.59,2.11,1.65S18.35,16.19,17,16.19Z"
    />
    <path
      class="cls-1"
      d="M25.1,14a3.77,3.77,0,0,0-3.8,4.09,3.81,3.81,0,1,0,7.59,0A3.76,3.76,0,0,0,25.1,14Zm0,6.67c-1.22,0-2-1-2-2.58s.76-2.58,2-2.58,2,1,2,2.58S26.31,20.66,25.1,20.66Z"
    />
    <polygon
      class="cls-1"
      points="36.78 19.35 35.37 14.13 33.89 14.13 32.49 19.35 31.07 14.13 29.22 14.13 31.59 22.01 33.15 22.01 34.59 16.85 36.03 22.01 37.59 22.01 39.96 14.13 38.18 14.13 36.78 19.35"
    />
    <path
      class="cls-1"
      d="M44,14a3.83,3.83,0,0,0-3.75,4.09,3.79,3.79,0,0,0,3.83,4.09A3.47,3.47,0,0,0,47.49,20L46,19.38a1.78,1.78,0,0,1-1.83,1.26A2.12,2.12,0,0,1,42,18.47h5.52v-.6C47.54,15.71,46.32,14,44,14Zm-1.93,3.13A1.92,1.92,0,0,1,44,15.5a1.56,1.56,0,0,1,1.69,1.62Z"
    />
    <path
      class="cls-1"
      d="M50.69,15.3V14.13h-1.8V22h1.8V17.87a1.89,1.89,0,0,1,2-2,4.68,4.68,0,0,1,.66,0v-1.8c-.14,0-.3,0-.51,0A2.29,2.29,0,0,0,50.69,15.3Z"
    />
    <path
      class="cls-1"
      d="M57.48,14a3.83,3.83,0,0,0-3.75,4.09,3.79,3.79,0,0,0,3.83,4.09A3.47,3.47,0,0,0,60.93,20l-1.54-.59a1.78,1.78,0,0,1-1.83,1.26,2.12,2.12,0,0,1-2.1-2.17H61v-.6C61,15.71,59.76,14,57.48,14Zm-1.93,3.13a1.92,1.92,0,0,1,1.92-1.62,1.56,1.56,0,0,1,1.69,1.62Z"
    />
    <path
      class="cls-1"
      d="M67.56,15a2.85,2.85,0,0,0-2.26-1c-2.21,0-3.47,1.85-3.47,4.09s1.26,4.09,3.47,4.09a2.82,2.82,0,0,0,2.26-1V22h1.8V11.24h-1.8Zm0,3.35a2,2,0,0,1-2,2.28c-1.31,0-2-1-2-2.52s.7-2.52,2-2.52c1.11,0,2,.81,2,2.29Z"
    />
    <path
      class="cls-1"
      d="M79.31,14A2.88,2.88,0,0,0,77,15V11.24h-1.8V22H77v-.83a2.86,2.86,0,0,0,2.27,1c2.2,0,3.46-1.86,3.46-4.09S81.51,14,79.31,14ZM79,20.6a2,2,0,0,1-2-2.28v-.47c0-1.48.84-2.29,2-2.29,1.3,0,2,1,2,2.52S80.25,20.6,79,20.6Z"
    />
    <path
      class="cls-1"
      d="M86.93,19.66,85,14.13H83.1L86,21.72l-.3.74a1,1,0,0,1-1.14.79,4.12,4.12,0,0,1-.6,0v1.51a4.62,4.62,0,0,0,.73.05,2.67,2.67,0,0,0,2.78-2l3.24-8.62H88.82Z"
    />
    <path
      class="cls-1"
      d="M125,12.43a3,3,0,0,0-2.13.87l-.14-.69h-2.39V25.53l2.72-.59V21.81a3,3,0,0,0,1.93.7c1.94,0,3.72-1.59,3.72-5.11C128.71,14.18,126.91,12.43,125,12.43Zm-.65,7.63a1.61,1.61,0,0,1-1.28-.52l0-4.11a1.64,1.64,0,0,1,1.3-.55c1,0,1.68,1.13,1.68,2.58S125.36,20.06,124.35,20.06Z"
    />
    <path
      class="cls-1"
      d="M133.73,12.43c-2.62,0-4.21,2.26-4.21,5.11,0,3.37,1.88,5.08,4.56,5.08a6.12,6.12,0,0,0,3-.73V19.64a5.79,5.79,0,0,1-2.7.62c-1.08,0-2-.39-2.14-1.7h5.38c0-.15,0-.74,0-1C137.71,14.69,136.35,12.43,133.73,12.43Zm-1.47,4.07c0-1.26.77-1.79,1.45-1.79s1.4.53,1.4,1.79Z"
    />
    <path
      class="cls-1"
      d="M113,13.36l-.17-.82h-2.32v9.71h2.68V15.67a1.87,1.87,0,0,1,2.05-.58V12.54A1.8,1.8,0,0,0,113,13.36Z"
    />
    <path
      class="cls-1"
      d="M99.46,15.46c0-.44.36-.61.93-.61a5.9,5.9,0,0,1,2.7.72V12.94a7,7,0,0,0-2.7-.51c-2.21,0-3.68,1.18-3.68,3.16,0,3.1,4.14,2.6,4.14,3.93,0,.52-.44.69-1,.69a6.78,6.78,0,0,1-3-.9V22a7.38,7.38,0,0,0,3,.64c2.26,0,3.82-1.15,3.82-3.16C103.62,16.12,99.46,16.72,99.46,15.46Z"
    />
    <path
      class="cls-1"
      d="M107.28,10.24l-2.65.58v8.93a2.77,2.77,0,0,0,2.82,2.87,4.16,4.16,0,0,0,1.91-.37V20c-.35.15-2.06.66-2.06-1V15h2.06V12.66h-2.06Z"
    />
    <polygon class="cls-1" points="116.25 11.7 118.98 11.13 118.98 8.97 116.25 9.54 116.25 11.7" />
    <rect class="cls-1" x="116.25" y="12.61" width="2.73" height="9.64" />
  </svg>
);
export const downloadIcon = (
  <svg class="download-icon" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.3287 7.04738L11.6697 6.29738C11.4877 6.09038 11.1727 6.07038 10.9647 6.25138L8.99969 7.97338V0.838379C8.99969 0.562379 8.77569 0.338379 8.49969 0.338379H7.49969C7.22369 0.338379 6.99969 0.562379 6.99969 0.838379V7.97338L5.03469 6.25138C4.82769 6.07038 4.51269 6.09038 4.32969 6.29738L3.67069 7.04638C3.48769 7.25438 3.50869 7.57138 3.71669 7.75338L7.67069 11.2144C7.85969 11.3794 8.14069 11.3794 8.32969 11.2144L12.2827 7.75338C12.4907 7.57138 12.5117 7.25438 12.3287 7.04738Z"
      fill="#7879f1"
    ></path>
    <path
      d="M13.4997 13.3389H2.49966C2.22366 13.3389 1.99966 13.5629 1.99966 13.8389V14.8389C1.99966 15.1149 2.22366 15.3389 2.49966 15.3389H13.4997C13.7757 15.3389 13.9997 15.1149 13.9997 14.8389V13.8389C13.9997 13.5629 13.7757 13.3389 13.4997 13.3389Z"
      fill="#7879f1"
    ></path>
  </svg>
);

export const closeIcon = (
  <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.88111 4.00011L7.8722 1.00889C7.95447 0.926545 7.99987 0.816691 8 0.699553C8 0.58235 7.9546 0.472366 7.8722 0.390155L7.61008 0.128106C7.52767 0.0455695 7.41782 0.000366211 7.30055 0.000366211C7.18348 0.000366211 7.07363 0.0455695 6.99122 0.128106L4.00013 3.11913L1.00891 0.128106C0.926634 0.0455695 0.816715 0.000366211 0.699512 0.000366211C0.582439 0.000366211 0.47252 0.0455695 0.390244 0.128106L0.128 0.390155C-0.0426667 0.560821 -0.0426667 0.838415 0.128 1.00889L3.11915 4.00011L0.128 6.9912C0.0456585 7.07367 0.000325203 7.18352 0.000325203 7.30066C0.000325203 7.4178 0.0456585 7.52765 0.128 7.61006L0.390179 7.87211C0.472455 7.95458 0.582439 7.99985 0.699447 7.99985C0.81665 7.99985 0.926569 7.95458 1.00885 7.87211L4.00006 4.88102L6.99115 7.87211C7.07356 7.95458 7.18341 7.99985 7.30049 7.99985H7.30062C7.41776 7.99985 7.52761 7.95458 7.61002 7.87211L7.87213 7.61006C7.95441 7.52772 7.9998 7.4178 7.9998 7.30066C7.9998 7.18352 7.95441 7.07367 7.87213 6.99126L4.88111 4.00011Z"
      fill="#8C979D"
    />
  </svg>
);

export const lang = (
  <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24">
    <path fill="none" d="M0 0h24v24H0V0z" />
    <path
      className="globe-path"
      fill="#8793ab"
      d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zm6.93 6h-2.95c-.32-1.25-.78-2.45-1.38-3.56 1.84.63 3.37 1.91 4.33 3.56zM12 4.04c.83 1.2 1.48 2.53 1.91 3.96h-3.82c.43-1.43 1.08-2.76 1.91-3.96zM4.26 14C4.1 13.36 4 12.69 4 12s.1-1.36.26-2h3.38c-.08.66-.14 1.32-.14 2s.06 1.34.14 2H4.26zm.82 2h2.95c.32 1.25.78 2.45 1.38 3.56-1.84-.63-3.37-1.9-4.33-3.56zm2.95-8H5.08c.96-1.66 2.49-2.93 4.33-3.56C8.81 5.55 8.35 6.75 8.03 8zM12 19.96c-.83-1.2-1.48-2.53-1.91-3.96h3.82c-.43 1.43-1.08 2.76-1.91 3.96zM14.34 14H9.66c-.09-.66-.16-1.32-.16-2s.07-1.35.16-2h4.68c.09.65.16 1.32.16 2s-.07 1.34-.16 2zm.25 5.56c.6-1.11 1.06-2.31 1.38-3.56h2.95c-.96 1.65-2.49 2.93-4.33 3.56zM16.36 14c.08-.66.14-1.32.14-2s-.06-1.34-.14-2h3.38c.16.64.26 1.31.26 2s-.1 1.36-.26 2h-3.38z"
    />
  </svg>
);

export const infoIcon = (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.9995 0C5.37295 0 0 5.37295 0 11.9995C0 18.626 5.37295 24 11.9995 24C18.626 24 24 18.626 24 11.9995C24 5.37295 18.626 0 11.9995 0ZM14.4975 18.5976C13.8799 18.8414 13.3882 19.0263 13.0194 19.1543C12.6517 19.2823 12.224 19.3463 11.7374 19.3463C10.9897 19.3463 10.4076 19.1634 9.99314 18.7987C9.57867 18.434 9.37244 17.9718 9.37244 17.41C9.37244 17.1916 9.38768 16.9681 9.41816 16.7406C9.44965 16.513 9.49943 16.257 9.56749 15.9695L10.3406 13.2389C10.4086 12.9768 10.4676 12.7279 10.5143 12.4963C10.561 12.2626 10.5834 12.0483 10.5834 11.8532C10.5834 11.5058 10.5112 11.262 10.368 11.1248C10.2227 10.9877 9.94946 10.9206 9.5421 10.9206C9.34298 10.9206 9.13778 10.9501 8.92749 11.0121C8.71924 11.0761 8.53841 11.134 8.3901 11.1909L8.59429 10.3497C9.10019 10.1435 9.58476 9.96673 10.047 9.82044C10.5092 9.67213 10.946 9.59899 11.3575 9.59899C12.1001 9.59899 12.673 9.77981 13.0763 10.1374C13.4776 10.496 13.6797 10.9623 13.6797 11.5352C13.6797 11.6541 13.6655 11.8634 13.6381 12.162C13.6107 12.4617 13.5589 12.735 13.4837 12.9859L12.7147 15.7084C12.6517 15.9269 12.5958 16.1768 12.545 16.4561C12.4952 16.7355 12.4709 16.9488 12.4709 17.0921C12.4709 17.4537 12.5511 17.7006 12.7137 17.8316C12.8742 17.9627 13.1556 18.0287 13.5538 18.0287C13.7417 18.0287 13.952 17.9952 14.1897 17.9302C14.4254 17.8651 14.5961 17.8072 14.7037 17.7575L14.4975 18.5976ZM14.3614 7.54692C14.0028 7.88013 13.571 8.04673 13.0662 8.04673C12.5623 8.04673 12.1275 7.88013 11.7658 7.54692C11.4062 7.21371 11.2244 6.80838 11.2244 6.33498C11.2244 5.8626 11.4072 5.45625 11.7658 5.12C12.1275 4.78273 12.5623 4.61511 13.0662 4.61511C13.571 4.61511 14.0038 4.78273 14.3614 5.12C14.72 5.45625 14.8998 5.8626 14.8998 6.33498C14.8998 6.8094 14.72 7.21371 14.3614 7.54692Z"
      fill="#643cdd"
    />
  </svg>
);
