import React, {memo} from 'react';
import { useTranslation } from 'react-i18next';
import {checkedIcon} from '../../img/svg-icons';
import './styles.scss';

const PaymentSuccess = ({handleClose}) => {
  const {t, i18n} = useTranslation();

  return (
    <div>
      <div className="mx-auto" style={{width: 'fit-content', marginTop: '4vh'}}>
        <div className="d-flex align-items-center justify-content-center success-icon">{checkedIcon}</div>
      </div>
      <h4 className="text-center mt-5">{t('congratulations')}</h4>
      <div className="d-flex align-items-center justify-content-center mt-5">
        <button className="btn confirm-and-pay" onClick={handleClose}>
          {t('close')}
        </button>
      </div>
    </div>
  );
};

export default memo(PaymentSuccess);
