import React, {memo, useContext, useEffect, useState} from 'react';
import {Helmet} from 'react-helmet';
import {useTranslation} from 'gatsby-plugin-react-i18next';
import logo from '../../img/logo.svg';
import {Link} from 'gatsby';
import Footer from '../footer';
import Header from '../header';
import {
  getRawCookie,
  getShowUpgradeModal,
  removeImpactClickIdCookie,
  removeImpactMediaPartnerIdCookie,
  setImpactClickIdCookie,
  setImpactMediaPartnerIdCookie,
  setShowUpgradeModal,
} from '../../helpers';
import './styles.scss';
import {UserContext} from '../../context/user-context';
import Payment from '../payment';
import Modal from '../modal';
import CookieConsentPopup from '../cookie-popup';

const Layout = ({children, title, metaName, metaContent, small, showLogo}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const {user} = useContext(UserContext);
  const {i18n} = useTranslation();
  const [paypal, setPaypal] = useState(false);
  const [elementParent, setElementParent] = useState(null);

  useEffect(() => {
    const val = getShowUpgradeModal();
    const parseVal = val ? JSON.parse(val) : [];
    if (parseVal && user && user.id && !parseVal.includes(user.id) && user.package_data.name === 'free') {
      setModalOpen(true);
    }
  }, [user]);

  useEffect(() => {
    // Setting Impact irclickid in cookies
    const url = window.location.href;
    if (url && url.indexOf('irclickid=') > -1) {
      if (getRawCookie('__irClickId')) {
        removeImpactClickIdCookie();
      }
      const id = url.slice(url.indexOf('irclickid=') + 10);
      const idFin = id.slice(0, id.indexOf('&'));
      setImpactClickIdCookie(idFin);
      removeImpactMediaPartnerIdCookie();
    } else if (url && url.toLocaleLowerCase().indexOf('mediapartnerid=') > -1) {
      if (getRawCookie('__mediaPartnerId')) {
        removeImpactMediaPartnerIdCookie();
      }
      const id = url.slice(url.toLocaleLowerCase().indexOf('mediapartnerid=') + 15);
      const idFin = id.slice(0, id.indexOf('&'));
      setImpactMediaPartnerIdCookie(idFin);
      removeImpactClickIdCookie();
    }
  }, []);

  //for ltd
  // useEffect(() => {
  //   if (global && global.window && global.window.location && global.window.location.href.includes('PayerID')) {
  //     localStorage.setItem('payment_success', 'true');
  //     window.close();
  //   }
  // }, []);

  useEffect(() => {
    if (global && global.window && global.window.location && global.window.location.href.includes('subscription_id')) {
      localStorage.setItem('payment_success', 'true');
      window.close();
    }
  }, []);

  return (
    <div>
      <div className="page-wrap has-text-centered">
        <Header showLogo={showLogo} />

        <section className="section">
          <Helmet>
            <title>{title}</title>
            <meta name={metaName} content={metaContent} />
            <html lang={i18n.language === 'ar' ? 'ar' : 'en'} dir={i18n.language === 'ar' ? 'rtl' : 'ltr'} />
            <link rel="canonical" href={global && global.window && global.window.location.href} />

            <meta property="og:title" content={title} />
            <meta property="og:description" content={metaContent} />
            <meta property="og:type" content="website" />
            <meta property="og:locale" content="en_US" />
            <meta property="og:url" content={global && global.window && global.window.location.href} />
            <meta property="og:image" content={'https://candidatechecker.io/CandidateChecker.png'} />

            <meta name="twitter:title" content={title} />
            <meta name="twitter:description" content={metaContent} />
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:image" content={'https://candidatechecker.io/CandidateChecker.png'} />
            <link rel="apple-touch-icon" sizes="180x180" href="https://candidatechecker.io/apple-touch-icon.png" />
            <link rel="icon" type="image/png" sizes="32x32" href="https://candidatechecker.io/favicon-32x32.png" />
            <link rel="icon" type="image/png" sizes="16x16" href="https://candidatechecker.io/favicon-16x16.png" />
            <link rel="manifest" href="https://candidatechecker.io/site.webmanifest" />
            <link rel="mask-icon" href="https://candidatechecker.io/safari-pinned-tab.svg" color="#222428" />
            <meta name="msapplication-TileColor" content="#ffffff" />
            <meta name="theme-color" content="#ffffff"></meta>
          </Helmet>
          <div className={`content ${small ? 'small' : ''}`}>
            {/* <div className="header d-none d-md-block">
              <img src={mail} className="mail-icon" />
              <img src={plane} className="plane-icon" />
            </div> */}
            {!showLogo && (
              <Link
                to={i18n.language === 'ar' ? '/ar/' : '/'}
                className="navbar-brand justify-content-center"
                style={{marginBottom: '55px', display: 'inline-block'}}
              >
                <img src={logo} alt="Candidate checker" className="position-sticky candidate-checker-logo" />
              </Link>
            )}
            {children}
          </div>
        </section>

        <Footer />
      </div>
      {modalOpen && (
        <Modal
          isOpen={modalOpen}
          modalToggle={setModalOpen}
          isEmpty
          isLarge={true}
          paypal={paypal}
          setPaypal={setPaypal}
        >
          <Payment
            handleClose={setModalOpen}
            showPaypalModal={val => setPaypal(val)}
            paypalBool={paypal}
            setElementParent={val => setElementParent(val)}
            elementParent={elementParent}
          />
        </Modal>
      )}
      <CookieConsentPopup />
    </div>
  );
};

export default memo(Layout);
