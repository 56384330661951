import React, {useState} from 'react';
import {useTranslation} from 'gatsby-plugin-react-i18next';
import {formatCreditCardNumber, formatExpirationDate, formatCVC} from './helpers';
import Visa from '../../img/payments/Visa.png';
import AmericanExpress from '../../img/payments/AmericanExpress.png';
import MasterCard from '../../img/payments/MasterCard.png';
import UnionPay from '../../img/payments/UnionPay.png';
import Discover from '../../img/payments/Discover.png';
import DinersClub from '../../img/payments/DinersClub.png';
import JCB from '../../img/payments/JCB.png';
import './styles.scss';
import {eyeIcon} from '../../img/svg-icons';

const CreditCardFormComponent = ({cardData, onFieldChange, onFieldBlur, onFieldFocus, disabled, fieldError}) => {
  const [showCVV, setShowCVV] = useState(false);
  const {t, i18n} = useTranslation();
  return (
    <>
      <div className="profile-payment-inputs">
        <div className="form-item">
          <span className={i18n.language === 'ar' ? 'text-right label' : 'label'}>{t('Cardholder-Name')}</span>
          <input
            name="name"
            placeholder={t('name-on-the-card')}
            value={cardData.name}
            onChange={onFieldChange}
            onFocus={onFieldFocus}
            onBlur={onFieldBlur}
            disabled={disabled}
            error={fieldError && !cardData.name}
            className={`input-field mt-1 ${fieldError && !cardData.name ? 'error-border' : ''} ${
              i18n.language === 'ar' ? 'typing-rl' : ''
            }`}
          />
        </div>
        <div className="form-item">
          <span className={i18n.language === 'ar' ? 'text-right label' : 'label'}>{t('Credit/Debit-Card-Number')}</span>
          <input
            name="number"
            placeholder={t('card-number')}
            value={formatCreditCardNumber(cardData.number)}
            onChange={onFieldChange}
            onFocus={onFieldFocus}
            onBlur={onFieldBlur}
            required
            disabled={disabled}
            error={fieldError && !cardData.number}
            className={`input-field mt-1 ${fieldError && !cardData.number ? 'error-border' : ''} ${
              i18n.language === 'ar' ? 'typing-rl' : ''
            }`}
          />
        </div>
        <div className="row">
          <div className="col-12 col-lg-6 col-sm-6">
            <div className="form-item">
              <span className={i18n.language === 'ar' ? 'text-right label' : 'label'}>{t('expiration')}</span>
              <input
                name="expiry"
                placeholder={t('mm/yy')}
                value={formatExpirationDate(cardData.expiry)}
                onChange={onFieldChange}
                onFocus={onFieldFocus}
                onBlur={onFieldBlur}
                required
                disabled={disabled}
                error={fieldError && !cardData.expiry}
                className={`input-field mt-1 ${fieldError && !cardData.expiry ? 'error-border' : ''} ${
                  i18n.language === 'ar' ? 'typing-rl' : ''
                }`}
              />
            </div>
          </div>
          <div className="col-12 col-lg-6 col-sm-6">
            <div className="form-item">
              <span className={i18n.language === 'ar' ? 'text-right label' : 'label'}>{t('security-code')}</span>
              <div className="input-wrapper">
                <input
                  type={showCVV ? 'text' : 'password'}
                  name="cvc"
                  placeholder="CVV/CVC"
                  className={`input-field mt-1 ${fieldError && !cardData.cvc ? 'error-border' : ''} ${
                    i18n.language === 'ar' ? 'typing-rl' : ''
                  }`}
                  value={formatCVC(cardData.cvc, cardData.number)}
                  onChange={onFieldChange}
                  onFocus={onFieldFocus}
                  onBlur={onFieldBlur}
                  required
                  disabled={disabled}
                  style={i18n.language === 'ar' ? {paddingLeft: '50px'} : {paddingRight: '50px'}}
                />
                <div
                  className={`eye-icon ${showCVV ? ' active' : ''} ${i18n.language === 'ar' ? 'left-align' : ''}`}
                  onClick={e => !disabled && setShowCVV(!showCVV)}
                >
                  {eyeIcon}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="payment-icons text-center d-flex align-items-center justify-content-center">
        <img src={Visa} alt="Visa" className="m-1" />
        <img src={MasterCard} alt="Master Card" className="m-1" />
        <img src={AmericanExpress} alt="American Express" className="m-1" />
        <img src={Discover} alt="Discover" className="m-1" />
        <img src={JCB} alt="JCB" className="m-1" />
        <img src={DinersClub} alt="Diners Club" className="m-1" />
        <img src={UnionPay} alt="Union Pay" className="m-1" />
      </div>
    </>
  );
};

export default CreditCardFormComponent;
