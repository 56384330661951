import React, {memo, useCallback, useContext, useEffect, useReducer, useState} from 'react';
import {navigate} from 'gatsby';
import {useTranslation} from 'gatsby-plugin-react-i18next';
import {useMediaQuery} from 'react-responsive';

import {UserContext} from '../../context/user-context';
import Upgrade from '../upgrade';
import {login, logOut} from '../../requests';
import googleIcon from '../../img/google-icon.svg';
import logo from '../../img/logo.svg';
import headerImg from '../../img/header.svg';
import headerImgMobile from '../../img/header-mobile.svg';
import mobileBanner from '../../img/banners/hexomatic-banner/mobile-banner.png';
import promoText from '../../img/banners/hexomatic-banner/promo-text.svg';
import background1 from '../../img/banners/hexomatic-banner/background-1.png';
import background2 from '../../img/banners/hexomatic-banner/background-2.png';
import Counter from '../../components/coundown';
import {closeIcon, lang} from '../../img/svg-icons';

import './styles.scss';
import {getPromoBanner, removeCookie, setPromoBanner} from '../../helpers';
import {changeLanguage} from 'i18next';
import {useI18next, Link} from 'gatsby-plugin-react-i18next';
import axios from 'axios';
// import backgroun1 from '../../img/banners/background-1.svg';
// import backgroun2 from '../../img/banners/background-2.svg';
// import promoText from '../../img/banners/promo-text.svg';
// import mobileBanner from '../../img/banners/banner-mobile.svg';
// import popupMobile from '../../img/banners/popup-mobile.svg';
// import popupDesktop from '../../img/banners/popup-desktop.svg';
// import Modal from '../../components/modal';

const countryListTraslation = [
  'AM',
  'SA',
  'EG',
  'DZ',
  'AE',
  'IQ',
  'JO',
  'MA',
  'KW',
  'TN',
  'BH',
  'QA',
  'OM',
  'LB',
  'LY',
  'PS',
  'YE',
  'SD',
  'SY',
  'EH',
];

const Header = ({showLogo}) => {
  const {languages, changeLanguage} = useI18next();
  const {t, i18n} = useTranslation();
  const {user, setUser} = useContext(UserContext);
  const [show, setShow] = useReducer(val => !val, false);
  const [showPromo, setShowPromo] = useState(!getPromoBanner('ltd-promo'));
  const [showTranslation, setShowTranslation] = useState(false);
  const isMobile = useMediaQuery({maxWidth: 767});

  const [showBanner, setShowBanner] = useState(false);
  const [showPromoBanner, setShowPromoBanner] = useState(false);
  const [openModal, setOpenModal] = useReducer(val => !val, false);
  const [showInvestPopup, setShowInverstPopup] = useState(false);

  const func = async () => {
    const res = await axios(`https://api.hexomatic.com/v2/app/country`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    const userCountry = res && res.data && res.data.countryCode;
    countryListTraslation.includes(userCountry) ? setShowTranslation(true) : setShowTranslation(false);
  };

  useEffect(() => {
    func();
  }, []);

  const handleLoginOrLogout = useCallback(async () => {
    if (user && user.id) {
      await logOut();
      removeCookie();
      setUser({});
      navigate(i18n.language === 'ar' ? '/ar/' : '/');
      return;
    }
    const url = await login(i18n.language === 'ar' ? 'ar' : 'en');
    if (url) {
      window.location.href = url;
    }
  }, [user]);

  useEffect(() => {
    const promoUrl =
      global &&
      global.window &&
      global.window.location &&
      global.window.location.href &&
      (global.window.location.href.includes('#promo') || global.window.location.href.includes('social-spy'));
    if (user && !user.id && !promoUrl) {
      setShowBanner(true);
      return;
    } else {
      setShowBanner(false);
    }
    if (promoUrl || (user && user.package_data && user.package_data.name === 'free')) {
      setShowPromoBanner(false);
    } else {
      setShowPromoBanner(false);
    }
  }, [user]);

  //   const handlePromoClose = () => {
  //   const el = document.getElementById('promo-header');
  //   //@ts-ignore
  //   el && el.animate({ opacity: 0 }, 150);
  //   setTimeout(() => {
  //     setShowPromo(false);
  //     setPromoBanner('ltd-promo', 'true');
  //   }, 150);
  // };

  // const {t, i18n} = useTranslation();

  useEffect(() => {
    const show = localStorage.getItem('become-investor-1');
    !show ? setShowInverstPopup(true) : setShowInverstPopup(false);
  }, []);

  const isMobileSm = useMediaQuery({maxWidth: 991});

  return (
    <>
      <header>
        {/* {showBanner && !showPromoBanner && (
          <>
            <img
              className="d-none d-sm-block cursor-pointer"
              src={headerImg}
              alt="header"
              style={{width: '100%', height: 'auto'}}
              onClick={() =>
                window.open(
                  'https://hexowatch.com?utm_source=candidatechecker&utm_medium=banner&utm_campaign=monitor_website',
                  '_blank',
                )
              }
            />
            <img
              className="d-block d-sm-none cursor-pointer"
              src={headerImgMobile}
              alt="header"
              style={{width: '100%', height: 'auto'}}
              onClick={() =>
                window.open(
                  'https://hexowatch.com?utm_source=candidatechecker&utm_medium=banner&utm_campaign=monitor_website',
                  '_blank',
                )
              }
            />
          </>
        )} */}

        {/* <div
          className="w-100 position-relative d-none d-lg-flex"
          style={{background: '#050A37', cursor: 'pointer'}}
          onClick={() => window.open('https://hexofy.com/?utm_source=candidateChecker&utm_medium=hellobar', '_blank')}
        >
          <img src={backgroun1} alt="" className="illustration1 d-none d-lg-flex" />
          <div className="w-100 d-flex">
            <img
              src={promoText}
              alt="Hexofy"
              className="my-auto mx-xl-auto promo-text"
              style={{zIndex: 100}}
            />
          </div>
          <img src={backgroun2} alt="" className="illustration2 d-none d-lg-flex" />
        </div> */}
        {/* <div
          className="w-100 position-relative d-flex d-lg-none"
          style={{background: '#050A37', cursor: 'pointer'}}
          onClick={() => window.open('https://hexofy.com/?utm_source=candidateChecker&utm_medium=hellobar', '_blank')}
        >
          <img src={mobileBanner} alt="Hexofy" className="m-auto" style={{maxWidth: '250px'}} />
        </div> */}

        
          <div
            className={`p-0 text-center d-flex flex-wrap justify-content-center cursor-pointer`}
            style={{background: '#AE13FF'}}
            id="promo-header"
            onClick={() => window.location.href = 'https://searchscraper.io/?utm_source=candidateChecker&utm_medium=hellobar'}
          >
            <div className="d-none d-lg-flex justify-content-center" style={{width: '100%', position: 'relative'}}>
              <img src={background1} alt="illustration1" className="illustration1" />
              <div className="d-flex justify-content-center position-relative m-auto">
                <img src={promoText} alt="" className="m-auto" style={{maxWidth: '700px'}} />
               
              </div>
              <img src={background2} alt="illustration2" className="illustration2 d-none d-xl-block" />
            </div>
            <div className="d-flex d-lg-none justify-content-center" style={{width: '100%'}}>
              <div className="d-flex d-md-flex-wrap justify-content-center position-relative">
                <img src={mobileBanner} alt="" className="px-2 mr-xl-4" style={{maxHeight: '150px'}} />
               
              </div>
            </div>
          </div>
        
        <div className="header-container  d-flex justify-content-between">
          <nav className="w-100 navbar  navbar-expand-md navbar-light ml-auto">
            {showLogo &&
              (isMobile ? (
                <Link to={'/'} style={{zIndex: 1000}}>
                  <img src={logo} alt="Candidate checker" style={{width: '120px', height: 'auto'}} />
                </Link>
              ) : (
                <Link className="text-right" to={'/'} style={{zIndex: 1000}}>
                  <img src={logo} alt="Candidate checker" style={{width: '120px', height: 'auto'}} />
                </Link>
              ))}

            <div className="container-fluid">
              {isMobile ? (
                <ul className="lang-switcher-mobile p-0" dir="ltr">
                  {languages.map(
                    lng =>
                      lng !== i18n.language && (
                        <li key={lng}>
                          <a
                            href="#"
                            onClick={e => {
                              e.preventDefault();
                              changeLanguage(lng);
                            }}
                            className={i18n.language === 'ar' ? 'ar' : ''}
                          >
                            {lang} {lng.toUpperCase()}
                          </a>
                        </li>
                      ),
                  )}
                </ul>
              ) : null}

              <button
                className={`navbar-toggler  ${show ? '' : 'collapsed'}`}
                onClick={setShow}
                type="button"
                data-testid="button"
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="menu_toggle ">
                  <span className="hamburger">
                    <span />
                    <span />
                    <span />
                  </span>
                  <span className="hamburger-cross">
                    <span />
                    <span />
                  </span>
                </span>
              </button>

              <div className={`collapse navbar-collapse justify-content-end d-none d-md-block`}>
                {user && (
                  <div className="navbar-nav">
                    <div className="nav-link upgrade-link" onClick={setOpenModal}>
                      {t('upgrade')}
                    </div>

                    {user && user.id && (
                      <>
                        <Link className="nav-link" to="/my-account/">
                          {t('my-account')}
                          {user.picture && (
                            <img
                              src={user.picture}
                              style={{width: '30px', height: '30px', borderRadius: '50%', margin: '0 10px'}}
                            />
                          )}
                        </Link>
                      </>
                    )}

                    {user && user.id ? (
                      <div className="nav-link" onClick={handleLoginOrLogout}>
                        {t('sign-out')}
                      </div>
                    ) : (
                      <div className="nav-link nav-sign-in">
                        <button className="button sign-in" onClick={handleLoginOrLogout}>
                          <>
                            <img
                              src={googleIcon}
                              style={{width: '20px', height: '20px', marginRight: '10px'}}
                              alt="Google"
                            />
                            {t('sign-in-with-Google')}
                          </>
                        </button>
                      </div>
                    )}
                  </div>
                )}
                {showTranslation && (
                  <ul className={`language p-0 ${i18n.language === 'ar' ? 'arabic' : ''}`}>
                    {languages.map(
                      lng =>
                        lng !== i18n.language && (
                          <li className="language-list" key={lng}>
                            <a
                              href="#"
                              onClick={e => {
                                e.preventDefault();
                                changeLanguage(lng);
                              }}
                            >
                              {lang} {lng.toUpperCase()}
                            </a>
                          </li>
                        ),
                    )}
                  </ul>
                )}
              </div>
            </div>
          </nav>
        </div>
        <div className={`${show ? 'show' : 'hide'} mobile-header-menu d-md-none`}>
          <nav id="mobile-header-menu">
            {user ? (
              <div className="navbar-nav">
                <div className="nav-link w-100 px-4 upgrade-link" onClick={setOpenModal}>
                  {t('upgrade')}
                </div>

                {user && user.id && (
                  <>
                    <Link className="nav-link w-100 px-4" to="/my-account/" style={{whiteSpace: 'nowrap'}}>
                      {t('my-account')}
                    </Link>
                  </>
                )}
                {user && user.id ? (
                  <div className="nav-link  w-100 pl-4" style={{whiteSpace: 'nowrap'}} onClick={handleLoginOrLogout}>
                    {t('sign-out')}
                  </div>
                ) : (
                  <button className="button sign-in pl-4" onClick={handleLoginOrLogout}>
                    <img src={googleIcon} style={{width: '20px', height: '20px', marginRight: '10px'}} alt="Google" />
                    {t('sign-in-with-Google')}
                  </button>
                )}
              </div>
            ) : (
              <></>
            )}
          </nav>
        </div>
      </header>
      {openModal && <Upgrade openModal={openModal} setOpenModal={setOpenModal} />}
      {/* {showInvestPopup ? (
        <Modal isOpen={showInvestPopup} modalToggle={() => {}} modalFooter={<></>} className="banner-modal">
          <span
            className="position-absolute close-icon"
            onClick={() => {
              setShowInverstPopup(false);
              localStorage.setItem('become-investor-1', 'true');
            }}
          >
            {closeIcon}
          </span>
          <img
            src={isMobileSm ? popupMobile : popupDesktop}
            alt="Become an investor in Hexact"
            onClick={() => window.open('https://hexofy.com', '_blank')}
          />
        </Modal>
      ) : null} */}
    </>
  );
};

export default memo(Header);
