import Cookies from 'js-cookie';
import moment from 'moment';
const CookieDomains = ['localhost', '.candidatechecker.io'];

export const setToken = value => {
  CookieDomains.map(domain => {
    Cookies.set('user-token', value, {
      expires: 30,
      domain: domain,
    });
  });
};

export const removeCookie = () => {
  CookieDomains.map(domain => {
    Cookies.remove('user-token', {
      expires: 30,
      domain,
    });
    return null;
  });
};

export const removeCookiePolicy = () => {
  CookieDomains.map(domain => {
    Cookies.remove('cookie_preference', {
      expires: 365,
      domain: domain,
    });
  });
};

export const setCookiePolicy = (value: string) => {
  CookieDomains.map(domain => {
    Cookies.set('cookie_preference', value, {
      expires: 365,
      domain: domain,
    });
  });
};

export const setImpactClickIdCookie = (value: string | null) => {
  CookieDomains.map(domain => {
    Cookies.set('__irClickId', value, {
      expires: 30,
      domain: domain,
    });
  });
};

export const setImpactMediaPartnerIdCookie = (value: string | null) => {
  CookieDomains.map(domain => {
    Cookies.set('__mediaPartnerId', value, {
      expires: 30,
      domain: domain,
    });
  });
};

export const removeImpactClickIdCookie = () => {
  CookieDomains.map(domain => {
    Cookies.remove('__irClickId', {
      expires: 30,
      domain: domain,
    });
  });
};

export const removeImpactMediaPartnerIdCookie = () => {
  CookieDomains.map(domain => {
    Cookies.remove('__mediaPartnerId', {
      expires: 30,
      domain: domain,
    });
  });
};

export const getRawCookie = (name: string): string | undefined => Cookies.get(name);

export const getDateAndTime = date => {
  return date && moment.utc(new Date(date).toISOString()).format('MMM DD, YYYY h:mm:ss A (UTC)');
};

export const getDate = date => {
  return date && moment.utc(new Date(date).toISOString()).format('MMM DD, YYYY');
};

export const setPromoBanner = (name: string, val: string) =>
  // @ts-ignore
  global.window && localStorage && localStorage.setItem(name, val);

export const getPromoBanner = (name: string) =>
  // @ts-ignore
  global.window && localStorage && localStorage.getItem(name);

export const setShowUpgradeModal = (val: string) =>
  // @ts-ignore
  global.window && localStorage && localStorage.setItem('show-upgrade-modal', val);

export const getShowUpgradeModal = () =>
  // @ts-ignore
  global.window && localStorage && localStorage.getItem('show-upgrade-modal');
