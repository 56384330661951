import 'react-credit-cards/es/styles-compiled.css';
import React, {useState, useEffect} from 'react';
import Cards from 'react-credit-cards';
import CreditCardFormComponent from './cardForm';
import Payment from 'payment';
import './styles.scss';

const CreditCardForm = ({onSubmit, cardInfo, loading, error, changeData, disabled, fieldError}) => {
  const [showPaymentMethod, setShowPaymentMethod] = useState(false);
  const [cardData, setCardData] = useState({
    cvc: '',
    expiry: '',
    focus: '',
    name: '',
    number: '',
  });
  const [formData, setFormData] = useState({
    cvc: '',
    expiry: '',
    focus: '',
    name: '',
    number: '',
  });

  useEffect(() => {
    setCardData(formData);
    setFormData(formData);
    setShowPaymentMethod(false);
  }, [cardInfo]);

  const fieldChange = e => {
    const target = e.target;
    setCardData({
      ...cardData,
      [target.name]: target.value,
    });
    setFormData({
      ...formData,
      [target.name]: target.value,
    });
  };

  const setFocusName = e => {
    const target = e.target;
    setCardData({
      ...cardData,
      focus: target.name,
    });
    setFormData({
      ...formData,
      focus: target.name,
    });
  };

  const unsetFocusName = e => {
    setCardData({
      ...cardData,
      focus: '',
    });
    setFormData({
      ...formData,
      focus: '',
    });
  };

  const handlePaymentFormSubmit = async () => {
    onSubmit && (await onSubmit(formData));
    setShowPaymentMethod(false);
  };
  useEffect(() => {
    changeData && changeData(formData);
  }, [formData]);
  return (
    <div className={`row mt-2 card-wrap`}>
      {/* <div className={`pt-2 col-12 col-md-6`}>
        {disabled ? (
          <div className="checked-card">
            <Cards
              cvc={cardInfo.cvc && cardInfo.cvc.toString().replace(/[0-9]/g, '*')}
              expiry={cardInfo.expiry.toString().replace(/[0-9]/g, '*')}
              focused={cardInfo.focus}
              name={cardInfo.name
                .toString()
                .replace(/[a-z,A-Z]/g, '*')
                .replace(/[0-9]/g, '*')}
              number={cardInfo.number.toString().replace(/[0-9]/g, '*')}
              issuer={Payment.fns.cardType(cardInfo.number)}
              preview
            />
          </div>
        ) : (
          <Cards
            cvc={cardData.cvc.toString().replace(/[0-9]/g, '*')}
            expiry={cardData.expiry.toString().replace(/[0-9]/g, '*')}
            focused={cardData.focus}
            name={cardData.name.toString().replace(/[a-z,A-Z]/g, '*')}
            number={cardData.number.toString().replace(/[0-9]/g, '*')}
            issuer={Payment.fns.cardType(cardData.number)}
            preview
          />
        )}
      </div> */}
      <div className="col-12">
        <CreditCardFormComponent
          onSubmit={handlePaymentFormSubmit}
          cardData={formData}
          loading={loading}
          onFieldBlur={unsetFocusName}
          onFieldFocus={setFocusName}
          onFieldChange={fieldChange}
          disabled={disabled}
          fieldError={fieldError}
        />
      </div>
      <p
        className={`text-center pl-3 text-center my-2 alert alert-danger py-1 payment-error ${
          error ? 'visible' : 'invisible'
        }`}
      >
        {error || ' error'}
      </p>
    </div>
  );
};

export default CreditCardForm;
