import React, {memo, useCallback, useContext, useState} from 'react';
import {UserContext} from '../../context/user-context';
import Modal from '../modal';
import Payment from '../payment';
import {login} from '../../requests';
import googleIcon from '../../img/google-icon.svg';
import {setShowUpgradeModal} from '../../helpers';
import {useTranslation} from 'react-i18next';

const Upgrade = ({openModal, setOpenModal, upgrade, limit_reached, simpleSignIn}) => {
  const {t, i18n} = useTranslation();
  const {user} = useContext(UserContext);
  const [show, setShow] = useState(false);
  const [paypal, setPaypal] = useState(false);
  const [elementParent, setElementParent] = useState(null);

  const handleClick = useCallback(async () => {
    if (upgrade) {
      setShow(true);
      return;
    }
    const url = await login(i18n.language === 'ar' ? 'ar' : 'en');
    if (url) window.location.href = url;
  }, [user, login, upgrade]);

  const ContactUs = () => (
    <div className="h-100 d-flex align-items-center justify-content-center">
      <div>
        {limit_reached ? (
          <>
            <h5 className="text-light text-center mb-3">{t('request-limit-reached')}</h5>
            <p className="text-light text-center mb-0">{t('need-to-upgrade')}</p>
          </>
        ) : (
          <h5 className="text-light">{t('need-to-upgrade')}</h5>
        )}
        <p className="text-light text-center mb-0">
          {t('please-contact')}{' '}
          <a
            href="mailto:billing@hexact.io?subject=Candidate Checker"
            className={`clickable-link ${i18n.language === 'ar' ? 'ltr' : ''}`}
            aria-label={'mail'}
            target="_blank"
            rel='noopener'
          >
            billing@hexact.io
          </a>
          {i18n.language === 'ar' ? '' : '.'}
        </p>
      </div>
    </div>
  );
  return (
    <>
      {openModal && (
        <Modal
          isOpen={openModal}
          modalToggle={setOpenModal}
          isEmpty
          isLarge={
            user &&
            user.id &&
            user.package_data &&
            user.package_data.name === 'free' &&
            (!upgrade || show) &&
            !simpleSignIn
          }
          paypal={paypal}
          setPaypal={setPaypal}
        >
          {simpleSignIn ? (
            <>
              <h5 className="mx-auto text-light mb-3">{t('sign-in-to-continue')}</h5>
              <button className="button sign-in mx-auto modal-btn" onClick={handleClick}>
                <img src={googleIcon} className="google-icon" alt="Google" />
                {t('sign-in-with-Google')}
              </button>
            </>
          ) : user && user.id && (!upgrade || show) ? (
            <>
              {user.package_data && user.package_data.name === 'free' ? (
                <Payment
                  handleClose={setOpenModal}
                  showPaypalModal={val => setPaypal(val)}
                  paypalBool={paypal}
                  setElementParent={val => setElementParent(val)}
                  elementParent={elementParent}
                />
              ) : (
                <ContactUs />
              )}
            </>
          ) : (
            <>
              {!upgrade ? (
                <>
                  {limit_reached && <h6 className="text-light text-center">{t('request-limit-reached')}</h6>}
                  <h5 className="mx-auto text-light mb-5">{t('sign-in-to-continue')}</h5>
                  <button className="button sign-in mx-auto modal-btn" onClick={handleClick}>
                    <img src={googleIcon} className="google-icon" alt="Google" />
                    {t('sign-in-with-Google')}
                  </button>
                </>
              ) : user.package_data && user.package_data.name !== 'free' ? (
                <>
                  <ContactUs />
                </>
              ) : (
                <>
                  {limit_reached && <h6 className="text-light text-center">{t('request-limit-reached')}</h6>}
                  <h5 className="mx-auto text-light mb-3">{t('upgrade-to-continue')}</h5>
                  <button className="button sign-in mx-auto modal-btn" onClick={handleClick}>
                    {t('upgrade')}
                  </button>
                </>
              )}
            </>
          )}
        </Modal>
      )}
    </>
  );
};

export default memo(Upgrade);
