import React, {memo, useCallback} from 'react';
import successIcon from '../../img/icons/success.svg';
import dangerIcon from '../../img/icons/danger.svg';
import './styles.scss';

const Modal = ({isOpen, modalToggle, title, onButtonClick, buttonText, children, isEmpty, isLarge, danger, paypal, className}) => {
  const handleClick = useCallback(() => {
    modalToggle && modalToggle();
  }, [modalToggle]);

  const preventDefault = useCallback(e => {
    e.preventDefault();
    e.stopPropagation();
  }, []);

  const handleClose = useCallback(
    e => {
      preventDefault(e);
      modalToggle && modalToggle();
    },
    [modalToggle],
  );

  return (
    <div
      className={`modal is-clipped ${isOpen ? 'is-active' : ''} ${isLarge ? 'large-modal' : ''} ${className ? className : ''}`}
      onClick={() => !paypal && handleClick()}
    >
      <div className="modal-background"></div>
      {paypal ? (
        children || title
      ) : isEmpty ? (
        <div className="modal-content position-relative" onClick={preventDefault}>
          {modalToggle ? <button
            className="modal-close is-large position-absolute close-modal-icon"
            aria-label="close"
            onClick={handleClose}
          ></button> :null}
          {children || title}
        </div>
      ) : (
        <>
          <div className="modal-content" onClick={preventDefault}>
            <img
              src={danger ? dangerIcon : successIcon}
              alt="icon"
              style={{height: '50px', width: '50px', margin: 'auto'}}
              className='popup-icon'
            />
            {children || <span className="text-light">{title}</span>}
            <div className="modal-content-footer">
              <button className="button m-auto" onClick={onButtonClick}>
                {buttonText}
              </button>
            </div>
          </div>
          {modalToggle ? <button className="modal-close is-large" aria-label="close" onClick={handleClose}></button> : null}
        </>
      )}
    </div>
  );
};

export default memo(Modal);
